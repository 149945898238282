<template>
  <div>
    <div v-if="isPreview">
      <personal-report
        :is-team-report="true"
        :member="member"
        :date-team-report="date"
      />
    </div>
    <div v-else>
      <base-empty :image="require('@/assets/image/no-board-report.svg')">
        <div class="text-lg capitalize font-medium text-black">
          {{ $t('empty.header') }}
        </div>
        <div class="font-normal text-desciption ">
          {{ $t('empty.description') }}
        </div>
        <select-member-teams class="my-4" @memberSelected="getMemberSelected">
          <base-button
            class="w-full"
            wide="px-5"
            color="white"
            style="max-width: 420px;"
            justify="start"
          >
            <span v-if="!member"> {{ $t('choose_member') }}</span>
            <span v-else class="truncate">{{ member.user.name }}</span>
            <div class="toggle-icon inline-flex has-centered-item px-2 ">
              <base-icon
                name="select-toggle"
                size="16"
                class="absolute mr-5 right-0"
              >
                <icon-chevron-down />
              </base-icon>
            </div>
          </base-button>
        </select-member-teams>
        <report-due-date
          class="justify-center w-full my-4 "
          color="white"
          :default-date-this-week-calendar="defaultDateThisWeekCalendar"
          :is-team-report="true"
          :select-date-from-team="3"
          :select-tab-date-from-team="$t('this_week')"
          style="color:#000000"
          @datePick="range"
        />
        <base-button
          class="mr-4 w-full"
          wide="px-10"
          :disabled="!member"
          @click="isPreview = true"
        >
          {{ $t('preview') }}
        </base-button>
      </base-empty>
    </div>
  </div>
</template>
<script>
import ReportDueDate from '@/components/report/report-due-date';
import moment from 'moment';
import SelectMemberTeams from '@/components/select/select-member-teams.vue';
import IconChevronDown from '@/components/icons/IconChevronDown.vue';
import PersonalReport from './PersonalReport.vue';

export default {
  components: {
    ReportDueDate,
    SelectMemberTeams,
    IconChevronDown,
    PersonalReport
  },
  data() {
    return {
      member: '',
      isPreview: false,
      date: null
    };
  },
  computed: {
    defaultDateThisWeekCalendar() {
      return {
        start: moment()
          .startOf('week')
          .toDate(),
        end: moment()
          .endOf('week')
          .toDate()
      };
    }
  },
  mounted() {
    this.$store.dispatch('teams/clearMembers');
    this.$store.dispatch('teams/fetchMembers', this.$route.params.id);
  },

  methods: {
    range(date) {
      const lastDay = moment(date.dateRange.start).subtract(1, 'day');
      date.dateRange.start = moment(lastDay).format('YYYY-MM-DD[T17:00:00Z]');
      date.dateRange.end = moment(date.dateRange.end).format(
        'YYYY-MM-DD[T16:59:59Z]'
      );
      this.date = date;
    },
    getMemberSelected(member) {
      this.member = member;
    }
  }
};
</script>
<i18n>
{
  "en": {
    "empty": {
      "header": "not enough information",
      "description": "Please select a member and duration to preview the team report."
    },
    "choose_member": "Choose Member",
    "preview": "Preview",
    "this_week": "This Week"
  },
  "th": {
     "empty": {
      "header": "ไม่พบข้อมูล",
      "description": "กรุณาเลือกสมาชิกและช่วงเวลา เพื่อแสดงข้อมูลรายงานของทีม"
    },
     "choose_member": "เลือกสมาชิก",
     "preview": "แสดงรายงาน",
     "this_week": "สัปดาห์นี้"
  }
}
</i18n>
